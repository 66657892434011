import React from "react";
import { Button, Image } from "@chakra-ui/react";
import {
  COLORS_DARK,
  COLORS_LIGHT,
} from "../colors/alearis_landing_page_colors";
import { useEffect } from "react";
import { useState } from "react";

interface AlearisButtonProps {
  position?: string;
  mt?: string;
  w?: string;
  h?: string;
  left?: string;
  top?: string;
  borderRadius?: string;
  backgroundColor?: string;
  zIndex?: string;
  onClick?: any;
  children: React.ReactNode;
  bottom?: string;
  right?: string | object;
  width?: string;
  fontSize?: string;
  opacity?: number;
  COLORS: object;
  image?: any;
}

const AlearisButton: React.FC<AlearisButtonProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);

  return (
    <Button
      size={"lg"}
      color={COLORS.WHITE}
      backgroundColor={COLORS.DARK_GREEN}
      {...props}
    >
      {props.children}
      <Image marginLeft=".5rem" src={props.image} />
    </Button>
  );
};

export default AlearisButton;
