import React, { useContext } from "react";
import backgroundPyramid from "../images/holding_pyramid.png";
import backgroundBuildings from "../images/holding_buildings.jpeg";
import { Image } from "@chakra-ui/image";
import alaerisLogoWithTrademark from "../images/alaerisLogoWithTrademark.svg";
import landingCityA from "../images/landingCityA.png";
import landingCityB from "../images/landingCityB.png";
import landingCityC from "../images/landingCityC.png";
import DiscordIcon from "../images/join_discord_icon.svg";
import AlearisButton from "../shared/alearis_button";
import {
  AleraisTextHoldingMedium,
  AleraisTextMedium,
  AleraisTextSmall,
} from "../shared/alearis_text";
import { Box } from "@chakra-ui/layout";
import { COLORS_DARK } from "../colors/alearis_landing_page_colors";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Center, Input, Stack } from "@chakra-ui/react";
import { alaerisApi } from "../shared/alaeris_api";
import validator from "validator";

interface HoldingPageProps {}

const HoldingPage: React.FC<HoldingPageProps> = (props) => {
  const [textOpacity, setTextOpacity] = useState(0);
  const [submittedEmailOpacity, setSubmittedEmailOpacity] = useState(0);
  const [background, setBackground] = useState();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>();

  const fade = async () => {
    for (var i = 0; i <= 100; i++) {
      await sleep(10);
      setTextOpacity(i * 0.01);
    }
  };

  const fadeSubmittedEmail = async () => {
    for (var i = 0; i <= 100; i++) {
      await sleep(15);
      setSubmittedEmailOpacity(i * 0.01);
    }
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const generateBackground = () => {
    if (Math.floor(Math.random() * 2) === 1) {
      // 50%
      return landingCityC;
    } else {
      if (Math.floor(Math.random() * 2) === 1) {
        // 25%
        return landingCityB;
      } else {
        // 25%
        return landingCityA;
      }
    }
  };

  const navigateToDiscord = () => {
    if (typeof window !== "undefined") {
      window.location.href = "https://discord.gg/ks7fRCvcDw";
    }
  };

  const handleEmailOnChange = (e) => {
    if (emailError !== null) {
      setEmailError(null);
    }
    setEmail(e.target.value);
  };

  const handleCollectEmail = async () => {
    if (validator.isEmail(email)) {
      const res = await alaerisApi.postEmail(email);
      fadeSubmittedEmail();
    } else {
      setEmailError(COLORS_DARK.SOFT_RED);
    }
  };

  useEffect(() => {
    setBackground(generateBackground());
    fade();
  }, []);

  return (
    <Box
      backgroundImage={{
        base: `linear-gradient(to top, rgb(39,39,39, 1) 0%, rgb(39,39,39, 0) 50%), url(${background})`,
        md: `linear-gradient(to right, rgb(39,39,39, 1) 0%, rgb(39,39,39, 0) 50%), url(${background})`,
      }}
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Image
        position="fixed"
        left={{ base: "2rem", md: "3rem" }}
        top="3rem"
        h={{ base: "5rem", md: "8rem" }}
        w={{ base: "5rem", md: "8rem" }}
        src={alaerisLogoWithTrademark}
      />
      <AlearisButton
        onClick={navigateToDiscord}
        opacity={textOpacity}
        height="2rem"
        paddingInline="1rem"
        paddingBlock="1.5rem"
        image={DiscordIcon}
        COLORS={COLORS_DARK}
        color="white"
        position="fixed"
        top="3rem"
        right={{ base: "2em", md: "none" }}
        display={{ base: "flex", md: "none" }}
      >
        Join Our Discord
      </AlearisButton>
      <Box
        padding={{ base: "2rem", md: "3rem" }}
        width={{ base: "100%", md: "50%" }}
        position="fixed"
        bottom={{ base: "1rem", md: ".28rem" }}
        opacity={textOpacity}
      >
        <AleraisTextHoldingMedium
          style={{ color: "white", fontWeight: "bold", marginBottom: "1rem" }}
        >
          We're planting something awesome, stay tuned.
        </AleraisTextHoldingMedium>
        <AleraisTextSmall
          style={{
            fontSize: "16px",
            lineHeight: 1.2,
            color: "white",
            textAlign: "left",
            marginBottom: "1.6rem",
          }}
        >
          Proof-of-Action&trade; is the next generation of Web3. Enter your email
          below and you'll be the first to know about the launch.
        </AleraisTextSmall>
        <Center>
          <Input
            onChange={(e) => {
              handleEmailOnChange(e);
            }}
            borderLeftColor={emailError}
            borderTopColor={emailError}
            borderBottomColor={emailError}
            borderWidth="3px"
            position="block"
            height="3rem"
            width={{ base: "65%", md: "80%" }}
            marginRight="0"
            borderRightRadius="0"
            backgroundColor="white"
            placeholder="you@email.com"
            bottom="0"
          ></Input>
          <AlearisButton
            borderRightColor={emailError}
            borderTopColor={emailError}
            borderBottomColor={emailError}
            position="relative"
            borderWidth="2.5px"
            COLORS={COLORS_DARK}
            color="white"
            height="3rem"
            width={{ base: "35%", md: "20%" }}
            marginLeft="0"
            borderLeftRadius="0"
            fontSize="1rem"
            onClick={handleCollectEmail}
          >
            Subscribe
          </AlearisButton>
        </Center>
        <AleraisTextSmall
          opacity={submittedEmailOpacity}
          style={{
            marginTop: "10px",
            fontSize: "12px",
            color: "white",
            textAlign: "left",
          }}
        >
          Your email has been submitted.
        </AleraisTextSmall>
      </Box>
      <AlearisButton
        opacity={textOpacity}
        height="2rem"
        paddingInline="1rem"
        paddingBlock="1.5rem"
        image={DiscordIcon}
        COLORS={COLORS_DARK}
        color="white"
        position="fixed"
        bottom="4.5rem"
        display={{ base: "none", md: "flex" }}
        right={{ base: "none", md: "4rem" }}
        onClick={navigateToDiscord}
      >
        Join Our Discord
      </AlearisButton>
    </Box>
  );
};

export default HoldingPage;
