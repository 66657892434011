export const COLORS_DARK = {
  STYLE: "dark",
  DARK_GRAY: "#393939",
  LIGHT_GRAY: "#f2f2f2",
  DARK_GREEN: "#1ca55b",
  TEXT: "#fdfdfd",
  BACKGROUND_ONE: "#272727",
  BACKGROUND_TWO: "#272727",
  HEADER_BACKGROUND: "#272727",
  HEADER_GRADIENT: "linear(rgb(39,39,39, 1) 0%, rgb(39,39,39, 0) 50%)",
  BOX_SHADOW: "10px 10px #111",
  SOFT_RED: "#F47174",
};

export const COLORS_LIGHT = {
  STYLE: "light",
  DARK_GRAY: "#ececec",
  LIGHT_GRAY: "#f2f2f2",
  DARK_GREEN: "#2b7a7c",
  WHITE: "#fff",
  BACKGROUND_ONE: "#f2f2f2",
  BACKGROUND_TWO: "#fff",
  HEADER_BACKGROUND: "#fff",
  HEADER_GRADIENT: "linear(rgb(270,270,270, 1) 0%, rgb(270,270,270, 0) 50%)",
  TEXT: "#272727",
  BOX_SHADOW: "10px 10px 16px 10px #BBBBBB",
};

export const COLORS = {
  DARK_GRAY: "#ececec",
  LIGHT_GRAY: "#f2f2f2",
  DARK_GREEN: "#1ca55b",
  WHITE: "#fff",
  BLACK: "#272727",
  BLACK_GRADIENT: "linear(rgb(39,39,39, 1) 0%, rgb(39,39,39, 0) 50%)",
};
