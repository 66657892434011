import { Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { COLORS_LIGHT } from "../colors/alearis_landing_page_colors";

interface AleraisTextProps {
  children: React.ReactNode;
  style?: object;
  opacity?: number;
}

export const AleraisTextLarge: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.TEXT}
      textAlign={{ base: "left", md: "left" }}
      fontSize={{ base: "36px", md: "64px" }}
      style={{ fontWeight: "bold" }}
    >
      {props.children}
    </Text>
  );
};

export const AleraisTextMedium: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.TEXT}
      textAlign={{ base: "left", md: "left" }}
      fontSize={{ base: "22px", md: "40px" }}
    >
      {props.children}
    </Text>
  );
};

export const AleraisTextHoldingMedium: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.TEXT}
      textAlign={{ base: "left", md: "left" }}
      fontSize={{ base: "32px", md: "38px" }}
      lineHeight="98.5%"
    >
      {props.children}
    </Text>
  );
};

export const AleraisTextSmall: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.TEXT}
      textAlign={{ base: "left", md: "center" }}
      fontSize={{ base: "18px", md: "20px" }}
      lineHeight="98.5%"
    >
      {props.children}
    </Text>
  );
};

export const AleraisTextCardHeader: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.TEXT}
      style={{ fontSize: "24px", fontWeight: "bold", margin: "2rem" }}
    >
      {props.children}
    </Text>
  );
};

export const AleraisTextCardBody: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.TEXT}
      style={{ fontSize: "16px", margin: "2rem" }}
    >
      {props.children}
    </Text>
  );
};

export const AleraisTextNav: React.FC<AleraisTextProps> = (props) => {
  const [COLORS, setCOLORS] = useState(COLORS_LIGHT);
  useEffect(() => {
    if (props.COLORS) {
      setCOLORS(props.COLORS);
    }
  }, [props.COLORS]);
  return (
    <Text
      {...props}
      color={COLORS.BLACK}
      style={{ fontSize: "24px", marginInline: "2rem" }}
    >
      {props.children}
    </Text>
  );
};
