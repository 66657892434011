import axios from "axios";

axios.defaults.baseURL = process.env.GATSBY_API_URL; // for all requests

export const alaerisApi = {
  getEmail: async () => {
    try {
      const res = await axios.get("/emails", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },

  postEmail: async (email: string) => {
    try {
      const res = await axios.post(
        "/emails",
        { email: email },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },
};
