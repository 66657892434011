import * as React from "react";

// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";
import Home from "../components/home/home";
import HoldingPage from "../components/holding_page/holding_page";
import { Helmet } from "react-helmet";

function IndexPage() {
  // 2. Use at the root of your app
  return (
    <ChakraProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alaeris</title>>
      </Helmet>
      <HoldingPage />
    </ChakraProvider>
  );
}

export default IndexPage;
